import React from "react";
import { useState, useEffect, useRef } from "react";
import "./sidebar.css";
import {
  Text,
  Box,
  Image,
  Heading,
  Flex,
  Container,
  Button,
  CloseButton,
  VStack,
  HStack,
  Divider,
  Spacer,
} from '@chakra-ui/react'
import Storage from "@aws-amplify/storage";
import { Linkify } from '../linkify';


function Sidebar(props) {

  // sidebar state
  const sidebarRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(400);
  const [imageUrls, setImageUrls] = useState({})

  // callbacks for resizing sidebar
  const startResizing = React.useCallback((mouseDownEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = React.useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = React.useCallback(
    (mouseMoveEvent) => {
      if (isResizing) {
        setSidebarWidth(
          sidebarRef.current.getBoundingClientRect().right - mouseMoveEvent.clientX
        );
      }
    },
    [isResizing]
  );

  // useEffect to get s3 image urls for activity/entries
  useEffect(() => {
    const getImageUrls = async () => {
      var urlObj = {}

      // loop through activity
      if (props.currentActivity.entries.items != null) {
        for (const entry of props.currentActivity.entries.items) {
          const entryImgList = await Storage.list(`map_entry_images/${props.currentActivity.id}/${entry.id}`);
          urlObj[entry.id] = []

          // loop through entries
          for (const entryImg of entryImgList) {
            const imgUrl = await Storage.get(entryImg.key);
            urlObj[entry.id].push(imgUrl)
          }
        }
        setImageUrls(urlObj);
      }
    }
    getImageUrls();
  }, [props.currentActivity]);

  React.useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  // return array of jsx image objects
  function generateImages(entry) {
    if (imageUrls[entry.id] !== undefined) {
      return Object.values(imageUrls[entry.id]).map(url =>
        <Container>
          <Flex
            key={url}
            align="center"
            justify="center"
            maxW='500px'
            pt={2}
            pb={2}
          >
            <Image src={url} />
          </Flex>
        </Container>
      );
    }
  }

  // function for unpacking descriptions
  // e is shortDesc entry, i is index
  function listDescriptions(activity) {
    if (activity.entries.items !== undefined) {
      // sort objects by date
      // per https://stackoverflow.com/a/10124053/13354634
      activity.entries.items.sort((a, b) => new Date(b.date) - new Date(a.date));

      // return jsx list of entries
      var descObject = activity.entries.items.map(function (entry) {
        return (
          <>
            <div key={entry.id}>
              <Divider m={4} />
              <Heading size='sm'> {entry.date} </Heading>
              <Text> {entry.description_short} </Text>
              <Linkify>
                <Text> {entry.description_long} </Text>
              </Linkify>
              <Text> {entry.photos} </Text>
              {generateImages(entry)}
            </div>
          </>
        )
      })
      return descObject;
    }
  }

  return (
    <div className="app-container">
      <div
        ref={sidebarRef}
        className="app-sidebar"
        style={{ width: sidebarWidth, display: props.sidebarVisibility }}
        onMouseDown={(e) => e.preventDefault()}
      >
        <div className="app-sidebar-content">
          {/* do headers */}
          <Flex direction='column' h='100%'>
            <Flex mb={2} >
              <Box >
                <VStack align='left'>
                  <Heading size='lg'>{props.currentActivity.name}</Heading>
                  <Heading size='md'>{props.currentActivity.type}</Heading>
                </VStack>
              </Box>
              <Spacer />
              <Box>
                <CloseButton onClick={() => {
                  props.closeSidebar();
                  props.clearCurrentActivity();
                }} />
              </Box>
            </Flex>
            {/* do entry */}
            <Flex mb={2} overflowY='scroll' overflowX='hidden'>
              <Box>
                {listDescriptions(props.currentActivity)}
              </Box>
            </Flex>
            <Spacer />
            {/* do button at bottom */}
            <HStack>
              <Flex >
                <Button size='sm' id='add-data-update'
                  onClick={e => {
                    if (props.authStatus) {
                      props.onEditOpen();
                      props.closeSidebar();
                    } else {
                      props.onAuthOpenFail();
                    }
                  }}
                  colorScheme='blue'>
                  Add Entry
                </Button>
              </Flex>
              <Flex >
                <Button size='sm' id='edit-data'
                  onClick={e => {
                    if (props.authStatus) {
                      props.onDeleteOpen();
                      props.closeSidebar();
                    } else {
                      props.onAuthOpenFail();
                    }
                  }}
                  colorScheme='blue'>
                  Edit Entries
                </Button>
              </Flex>
              <Flex >
                <Button size='sm' id='delete-data'
                  onClick={e => {
                    if (props.authStatus) {
                      props.deleteActivity(props.currentActivity.id);
                      props.closeSidebar();
                    } else {
                      props.onAuthOpenFail();
                    }
                  }}
                  colorScheme='red'>Delete Activity
                </Button>
              </Flex>
            </HStack>
          </Flex>
        </div>
        <div className="app-sidebar-resizer" onMouseDown={startResizing} />
      </div>
    </div>
  );
}

export default Sidebar;
