import {
    Modal,
    Text,
    ModalContent,
    ModalOverlay,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalCloseButton,
    RangeSlider,
    RangeSliderFilledTrack,
    RangeSliderTrack,
    RangeSliderThumb,
    Button,
    Box,
    Checkbox,
    Stack,
    HStack,
    FormControl,
    FormLabel,
    VStack,
    Divider,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Tabs, TabList, TabPanels, Tab, TabPanel
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import * as queries from '../graphql/queries'
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import moment from "moment/moment";
import { getColor } from "./map";

function DataViewer(props) {
    const [checkedItems, setCheckedItems] = useState(
        {
            "Boat": false,
            "Ride": false,
            "Run": false,
            "Ski": false,
            "Hike": false,
            "Other": false
        }
    )
    const allChecked = Object.values(checkedItems).every(v => v === true)
    const isIndeterminate = Object.values(checkedItems).some(v => v === true) && !allChecked
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [dateRange, setDateRange] = useState(null)
    const [customEntries, setCustomEntries] = useState([]);
    const [reportArray, setReportArray] = useState([]);
    const [plotData, setPlotData] = useState(null);
    const [activityCounts, setActivityCounts] = useState(null);

    const chartOptions = {
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${moment(context.parsed.x).format("MMMM YYYY")}, ${context.parsed.y} Activities`
                    }
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    callback: function (val, index) {
                        return moment(val).format("MM/DD/YY");
                    },
                    maxRotation: 45,
                    minRotation: 45,
                },
                title: {
                    display: true,
                    text: 'Month',
                },
            },
            y: {
                title: {
                    display: true,
                    text: '# Activites',
                },
            },
        },
    };


    // get custom entries data
    useEffect(() => {
        const fetchEntriesData = async () => {
            const result = await API.graphql({
                query: queries.listActivitycustoms,
                variables: {
                    limit: 100000,  // punt on pagination; if you get this many activities then just rewrite it using supabase
                }
            });
            const entries = [];
            for (const activity of result.data.listActivitycustoms.items) {
                if (activity.entries.items.length > 0) {
                    for (const idx in activity.entries.items) {
                        const entry = activity.entries.items[idx]
                        entry['type'] = activity.type
                        entry['activityName'] = activity.name
                        entries.push(entry)

                    }
                }
            }
            setCustomEntries(entries)
        };
        fetchEntriesData()
    }, []
    );

    // build out date arrays
    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }


    useEffect(() => {
        function getDates(startDate, stopDate) {
            var dateArray = [];
            var currentDate = startDate;
            while (currentDate <= stopDate) {
                dateArray.push(new Date(currentDate));
                currentDate = currentDate.addDays(1);
            }
            return dateArray;
        };
        const maxDate = new Date(Math.max(...customEntries.map(entry => new Date(entry.date))));
        const minDate = new Date(2017, 1, 1);
        const dateRange = getDates(minDate, maxDate)
        setDateRange(dateRange)
        setStartDate(dateRange[0])
        setEndDate(dateRange[dateRange.length - 1])
    }, [customEntries])

    function generateReport() {

        // create report array
        var reportArray = [];
        customEntries.forEach((entry, i) => {
            if (
                new Date(entry.date).getTime() >= new Date(startDate).getTime() &
                new Date(entry.date).getTime() <= new Date(endDate).getTime() &
                checkedItems[entry.type]
            ) {
                reportArray.push(entry);
            }
        })
        reportArray.sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
        });
        setReportArray(reportArray)
        console.log(reportArray)

        // create activity counts
        const activityCounts = reportArray.reduce((acc, entry) => {
            if (!acc[entry.type]) {
                acc[entry.type] = 0;
            }
            acc[entry.type] += 1;
            return acc;
        }, {})
        setActivityCounts(activityCounts)

        // create scatter data
        const groupedData = reportArray.reduce((acc, entry) => {
            const binKey = new Date(new Date(entry.date).setDate(1)).toLocaleDateString("en-US")

            if (!acc[entry.type]) {
                acc[entry.type] = {};
            }

            if (!acc[entry.type][binKey]) {
                acc[entry.type][binKey] = 0;
            }

            acc[entry.type][binKey] += 1;

            return acc;
        }, {});

        // Transform grouped data into the desired format
        const plotData = {
            datasets: Object.entries(groupedData).map(([groupId, groupData]) => ({
                label: groupId,
                data: Object.entries(groupData).map(([date, cnt]) => ({ x: Date.parse(date), y: cnt })),
                backgroundColor: getColor(groupId)
            }))
        };
        setPlotData(plotData)

    }

    return (
        <>
            <Modal size="xl" isOpen={props.isDataOpen} onClose={() => {
                props.onDataClose();
            }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Data Viewer</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack align='flex-start' spacing='24px'>
                            <>
                                <FormControl as='fieldset'>
                                    <VStack align='flex-start'>
                                        <FormLabel as='legend'>
                                            Select Activity Type(s)
                                        </FormLabel>
                                        <Checkbox
                                            isChecked={allChecked}
                                            isIndeterminate={isIndeterminate}
                                            onChange={(e) => {
                                                setCheckedItems(
                                                    Object.fromEntries(
                                                        Object.keys(checkedItems).map(key => [key, e.target.checked])
                                                    )
                                                )
                                            }}
                                        >
                                            All Activities
                                        </Checkbox>
                                        <Stack spacing={[10, 3]} direction={['column', 'row']} marginLeft={5}>
                                            {Object.entries(checkedItems).map(([selectedKey, selectedValue]) => (
                                                <Checkbox
                                                    key={selectedKey}
                                                    isChecked={selectedValue}
                                                    onChange={(e) => {
                                                        setCheckedItems(
                                                            Object.fromEntries(
                                                                Object.keys(checkedItems).map(mk => mk === selectedKey ? [mk, e.target.checked] : [mk, checkedItems[mk]])
                                                            )
                                                        )
                                                    }}>
                                                    {selectedKey}
                                                </Checkbox>
                                            ))}
                                        </Stack>
                                        <HStack align='stretch'>
                                            <FormLabel>
                                                Select Date Range:
                                            </FormLabel>
                                            <Text as='samp'>
                                                {moment(startDate).format("MM/DD/YY")} - {moment(endDate).format("MM/DD/YY")}
                                            </Text>
                                        </HStack>
                                        <RangeSlider
                                            aria-label={['min', 'max']}
                                            colorScheme='pink'
                                            defaultValue={[0, dateRange?.length]}
                                            min={0}
                                            max={dateRange?.length}
                                            onChange={(val) => {
                                                const startidx = val[0]
                                                const endidx = val[1] - 1
                                                setStartDate(dateRange[startidx])
                                                setEndDate(dateRange[endidx])
                                            }}
                                        >
                                            <RangeSliderTrack>
                                                <RangeSliderFilledTrack />
                                            </RangeSliderTrack>
                                            <RangeSliderThumb index={0} />
                                            <RangeSliderThumb index={1} />
                                        </RangeSlider>
                                    </VStack>
                                </FormControl>
                            </>
                            <Button onClick={() => generateReport()}>
                                Explore Data!
                            </Button>
                            <Divider />
                            <VStack spacing={'10px'} align='left'>
                                <Text as='b'>
                                    Total Activities: {reportArray.length}
                                </Text>
                                <HStack>
                                    {activityCounts != null &&
                                        Object.entries(activityCounts).map(([activity, count]) =>
                                            <Text key={activity}>
                                                {activity}: {count}
                                            </Text>
                                        )}
                                </HStack>
                            </VStack>
                            <Box width={'100%'}>
                                <Tabs>
                                    <TabList>
                                        <Tab>Graph</Tab>
                                        <Tab>Table</Tab>
                                    </TabList>

                                    <TabPanels>
                                        <TabPanel>
                                            <Box width={'100%'} height={'40vh'}>
                                                {plotData != null &&
                                                    <Chart
                                                        type='scatter'
                                                        data={plotData}
                                                        options={chartOptions}
                                                    />
                                                }
                                            </Box>
                                        </TabPanel>
                                        <TabPanel>
                                            <TableContainer >
                                                <Table size='sm' >
                                                    <Thead>
                                                        <Tr>
                                                            <Th>Type</Th>
                                                            <Th>Date</Th>
                                                            <Th>Activity Name</Th>
                                                            <Th>Summary</Th>
                                                            <Th>Description</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {
                                                            reportArray != null && reportArray.map((entry, idx) =>
                                                                <Tr key={entry.id}>
                                                                    <Td>{entry.type}</Td>
                                                                    <Td>{entry.date}</Td>
                                                                    <Td>{entry.activityName}</Td>
                                                                    <Td>{entry.description_short}</Td>
                                                                    <Td>{entry.description_long}</Td>
                                                                </Tr>
                                                            )
                                                        }
                                                    </Tbody>
                                                </Table>
                                            </TableContainer>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </Box>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                    </ModalFooter>
                </ModalContent >
            </Modal >
        </>
    )
}

export default DataViewer;
// {
//     "id": "f968ee78-0635-4d99-8bce-153723f71770",
//         "date": "2020-06-14",
//             "description_short": "550 cfs - II+",
//                 "description_long": "two laps, total and complete raft destruction on the second lap",
//                     "photos": [],
//                         "createdAt": "2022-07-07T03:54:10.978Z",
//                             "updatedAt": "2022-07-07T03:54:10.978Z",
//                                 "activitycustomEntriesId": "793f4162-2897-4a7a-9fb9-ab5f1b558fa0",
//                                     "type": "Boat",
//                                         "activityName": "Clear Creek - 1st Tunnel Down"
// }