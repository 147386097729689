import { useMapEvents, Polyline } from "react-leaflet";
import { React, useState, useEffect } from 'react';
import { FiSkipBack, FiCheck, FiSlash } from 'react-icons/fi';
import L from 'leaflet';
import {
  IconButton,
  VStack,
} from '@chakra-ui/react'
import './draw.css'

function PolylineGenerator(props) {
  const [polyline, setPolyline] = useState(
    <Polyline positions={props.polylineCoords} />
  )
  useEffect(() => {
    setPolyline(
      <Polyline positions={props.polylineCoords} />
    )
  }, [props.polylineCoords]);

  useMapEvents({
    click: (e) => {
      if (e.originalEvent.target.className === 'draw-container') {
        var newPolylineCoords = [...props.polylineCoords]
        newPolylineCoords.push([e.latlng.lat, e.latlng.lng])
        props.setPolylineCoords(newPolylineCoords)
      }
    },
  });

  return (
    <div>
      {polyline}
    </div>
  )
}

function Draw(props) {
  const [polylineCoords, setPolylineCoords] = useState([]);


  function undoPolyline() {
    let newPolylineCoords = [...polylineCoords]
    newPolylineCoords.pop()
    setPolylineCoords(newPolylineCoords)
  }

  function confirmPolyline() {
    // load the polyline, convert, and save
    var polyline = L.polyline(polylineCoords);

    if (polylineCoords.length !== 0) {
      let ca = props.currentActivity;
      ca.geometry = polyline.encodePath();
      props.setCurrentActivity(ca)
    }

    // close windows
    props.onEditOpen();
    props.setDrawMode(false);

    // reset state
    setPolylineCoords([]);

  }

  function deletePolyline() {
    setPolylineCoords([])
  }

  return (
    props.drawMode ?
      <>
        <div className='edit-buttons'>
          <VStack>
            <IconButton m={1} id='undo' colorScheme='blue' size='xs' icon={<FiSkipBack />} onClick={undoPolyline} />
            <IconButton m={1} id='confirm' colorScheme='blue' size='xs' icon={<FiCheck />} onClick={confirmPolyline} />
            <IconButton m={1} id='delete' colorScheme='blue' size='xs' icon={<FiSlash />} onClick={deletePolyline} />
          </VStack>
        </div>
        <div className='draw-container'>
          <PolylineGenerator
            polylineCoords={polylineCoords}
            setPolylineCoords={setPolylineCoords}
            {...props}
          />
        </div>
      </>
      :
      null
  )
}

export default Draw
