import Helmet from "react-helmet"
import Map from './components/map'
import React from 'react';
import { ChakraProvider } from '@chakra-ui/react'
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports'

Amplify.configure(awsconfig);


function App() {

  return (
    <>
      <Helmet>
        <title> Map - Rio McMahon </title>
        <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
        integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
        crossOrigin=""/>
        <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/leaflet.draw.css"/>
        <script src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"
         integrity="sha512-XQoYMqMTK8LvdxXYG3nZ448hOEQiglfqkJs1NOQV44cWnUrBc8PkAOcXy20w0vlaXaVUearIOBhiXZ5V3ynxwA=="
         crossOrigin=""></script>

      </Helmet>
      <ChakraProvider>
        <div className="App">
          <Map />
        </div>
      </ChakraProvider>
    </>
  );
}

export default App;
