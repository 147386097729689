/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createActivitycustom = /* GraphQL */ `
  mutation CreateActivitycustom(
    $input: CreateActivitycustomInput!
    $condition: ModelActivitycustomConditionInput
  ) {
    createActivitycustom(input: $input, condition: $condition) {
      id
      name
      type
      geometry
      polygon
      entries {
        items {
          id
          date
          description_short
          description_long
          photos
          createdAt
          updatedAt
          activitycustomEntriesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateActivitycustom = /* GraphQL */ `
  mutation UpdateActivitycustom(
    $input: UpdateActivitycustomInput!
    $condition: ModelActivitycustomConditionInput
  ) {
    updateActivitycustom(input: $input, condition: $condition) {
      id
      name
      type
      geometry
      polygon
      entries {
        items {
          id
          date
          description_short
          description_long
          photos
          createdAt
          updatedAt
          activitycustomEntriesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteActivitycustom = /* GraphQL */ `
  mutation DeleteActivitycustom(
    $input: DeleteActivitycustomInput!
    $condition: ModelActivitycustomConditionInput
  ) {
    deleteActivitycustom(input: $input, condition: $condition) {
      id
      name
      type
      geometry
      polygon
      entries {
        items {
          id
          date
          description_short
          description_long
          photos
          createdAt
          updatedAt
          activitycustomEntriesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEntry = /* GraphQL */ `
  mutation CreateEntry(
    $input: CreateEntryInput!
    $condition: ModelEntryConditionInput
  ) {
    createEntry(input: $input, condition: $condition) {
      id
      date
      description_short
      description_long
      photos
      createdAt
      updatedAt
      activitycustomEntriesId
    }
  }
`;
export const updateEntry = /* GraphQL */ `
  mutation UpdateEntry(
    $input: UpdateEntryInput!
    $condition: ModelEntryConditionInput
  ) {
    updateEntry(input: $input, condition: $condition) {
      id
      date
      description_short
      description_long
      photos
      createdAt
      updatedAt
      activitycustomEntriesId
    }
  }
`;
export const deleteEntry = /* GraphQL */ `
  mutation DeleteEntry(
    $input: DeleteEntryInput!
    $condition: ModelEntryConditionInput
  ) {
    deleteEntry(input: $input, condition: $condition) {
      id
      date
      description_short
      description_long
      photos
      createdAt
      updatedAt
      activitycustomEntriesId
    }
  }
`;
