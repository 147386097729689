import {
  Modal,
  ModalOverlay,
  ModalContent,
  Input,
  Box,
  Stack,
  FormLabel,
  FormControl,
  Button,
  Textarea,
  Flex,
  IconButton,
  FormErrorMessage,
  Select,
  Text,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik';
import { FiEdit2 } from 'react-icons/fi';
import { PiPolygon } from "react-icons/pi";

function ActivityEdit(props) {

  // use state for photos
  var upload = null;

  // unpack form inputs from props if necessary
  const formInputs = {
    activityName: props.currentActivity.name == null ? null : props.currentActivity.name,
    activityType: props.currentActivity.type == null ? 'default' : props.currentActivity.type,  // define default since not text input
    activityGeom: props.currentActivity.geometry == null ? null : props.currentActivity.geometry,
    activityPolygon: props.currentActivity.polygon == null ? false : props.currentActivity.polygon,
    activityDescShort: props.currentEntry.description_short == null ? null : props.currentEntry.description_short,
    activityDescLong: props.currentEntry.description_long == null ? null : props.currentEntry.description_long,
    activityDate: props.currentEntry.date == null ? null : props.currentEntry.date,
    activityPhotos: [],
  }

  const formPlaceHolders = {
    activityName: "Enter activity Name",
    activityType: "",
    activityGeom: "Polyline Encoded String",
    activityDate: "Add date of activity (YYYY-MM-DD)",
    activityDescShort: "Add short description of route",
    activityDescLong: "Add description of activity",
    activityPhotos: "Add S3 links to photos"
  }

  // helper function for name/date validation
  function validateName(value) {
    let error
    if (!value || value === 'default') {
      error = 'Value is required'
    }
    return error
  }

  function validateDate(value) {
    let error
    if (isNaN(Date.parse(value)) || value === 'default') {
      error = 'Date is required (YYYY-MM-DD)'
    }
    return error
  }
  return (
    <>
      <Modal isOpen={props.isEditOpen} onClose={() => {
        props.onEditClose();
        props.clearCurrentEntry();
      }}>
        <ModalOverlay />
        <ModalContent>
          <Formik
            initialValues={formInputs}
            onSubmit={(values, actions) => {
              values.activityPolygon = props.isPolygon  // hacky because I wanted pretty icon button 
              props.uploadData(values, actions, props.photoFileList);
            }}
            validator={() => ({})}
          >
            {(fprops) => (
              <Box p={4}>
                <Form>
                  <Stack direction='column' spacing={4} align='left'>
                    <FormLabel htmlFor='name'>
                      {props.currentActivity.id == null ? "Create New Activity" : "Update Existing Activity"}
                    </FormLabel>
                    <Field name='activityName' validate={validateName} >
                      {({ field, form }) => (
                        <FormControl>
                          <Input
                            {...field}
                            id='activityName'
                            value={formInputs.activityName}
                            placeholder={formPlaceHolders.activityName}
                            isDisabled={formInputs.activityName == null ? false : true}
                          />
                          <FormErrorMessage>{form.errors.activityName}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name='activityType'>
                      {({ field, form }) => (
                        <FormControl isInvalid={form.errors.activityType && form.touched.activityType}>
                          <Select
                            {...field}
                            id='activityType'
                            isDisabled={formInputs.activityType !== 'default' ? true : false}
                          >
                            <option value='default'>Select an Option</option>
                            <option value='Boat'>Boat</option>
                            <option value='Ride'>Ride</option>
                            <option value='Run'>Run</option>
                            <option value='Ski'>Ski</option>
                            <option value='Hike'>Hike</option>
                            <option value='Other'>Other</option>
                          </Select>
                          <FormErrorMessage>{form.errors.activityType}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Flex>
                      <Field name='activityGeom' validate={validateName} >
                        {({ field, form }) => (
                          <FormControl isInvalid={form.errors.activityGeom && form.touched.activityGeom}>
                            <Input
                              {...field}
                              id='activityGeom'
                              value={formInputs.activityGeom}
                              placeholder={formPlaceHolders.activityGeom}
                              isDisabled={formInputs.activityGeom == null ? false : true}
                            />
                            <FormErrorMessage>{form.errors.activityGeom}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <IconButton m={1} id='undo' icon={<FiEdit2 />} size='sm' variant='outline' onClick={() => {
                        props.setDrawMode(true);
                        props.onEditClose();
                      }} />
                      <Box>
                        <IconButton
                          m={1}
                          id='activityPolygon'
                          icon={<PiPolygon />}
                          size='sm'
                          colorScheme='teal'
                          variant={props.isPolygon ? 'solid' : 'outline'}
                          onClick={() => {
                            props.setIsPolygon(!props.isPolygon);
                          }}
                        />
                      </Box>
                    </Flex>

                    <Field name='activityDate' validate={validateDate} >
                      {({ field, form }) => (
                        <FormControl isInvalid={form.errors.activityDate && form.touched.activityDate}>
                          <Input
                            {...field}
                            id='activityDate'
                            placeholder={formPlaceHolders.activityDate}
                          />
                          <FormErrorMessage>{form.errors.activityDate}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name='activityDescShort' >
                      {({ field, form }) => (
                        <FormControl>
                          <Textarea
                            {...field}
                            id='activityDescShort'
                            placeholder={formPlaceHolders.activityDescShort}
                          />
                        </FormControl>
                      )}
                    </Field>
                    <Field name='activityDescLong' >
                      {({ field, form }) => (
                        <FormControl>
                          <Textarea
                            {...field}
                            id='activityDescLong'
                            placeholder={formPlaceHolders.activityDescLong}
                          />
                        </FormControl>
                      )}
                    </Field>
                    <Box>
                      <Text>
                        {props.currentEntry.photos}
                      </Text>
                    </Box>

                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      ref={ref => (upload = ref)}
                      onChange={e => {
                        props.setPhotoFileList(upload.files)
                      }}
                      multiple
                    />

                  </Stack>
                  <Box mt={4} >
                    <Stack direction='row' spacing={4} align='center'>
                      <Button colorScheme='teal' variant='solid' isLoading={props.isSubmitting} type='submit'>
                        {props.currentActivity.id == null ? "Save Activity" : "Update Activity"}
                      </Button>
                      <Button colorScheme='red' variant='outline' onClick={() => {
                        props.onEditClose();
                        props.clearCurrentEntry();
                      }} type='button'>
                        Cancel
                      </Button>
                    </Stack>
                  </Box>
                </Form>
              </Box>
            )}
          </Formik>
        </ModalContent>
      </Modal >
    </>
  )
}

export default ActivityEdit;
