import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "./Legend.css";

function Legend(colors) {
  const map = useMap();
  useEffect(() => {
    if (map) {
      const legend = L.control();
      var colors = {
        "Boat": "#FD314E",
        "Ride": "#F55AFA",
        "Run": "#7A33ED",
        "Ski": "#1BA1D7",
        "Hike": "#13C772",
        "Other": "#9E9200"
      };

      legend.onAdd = () => {
        const div = L.DomUtil.create("div", "info legend");
        const labels = ['<strong>Activity Type</strong>'];
        Object.keys(colors).forEach(function (label, index) {
            div.innerHTML +=
              labels.push(
                `<i class="dot" style="background:${colors[label]}"></i>&nbsp;${(label ? label : '+')}`
              )
        });
        div.innerHTML = labels.join('<br>');
        return div;
      };

      legend.addTo(map);
    }
  }, []);
  return null;
}

export default Legend;
