/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getActivitycustom = /* GraphQL */ `
  query GetActivitycustom($id: ID!) {
    getActivitycustom(id: $id) {
      id
      name
      type
      geometry
      polygon
      entries {
        items {
          id
          date
          description_short
          description_long
          photos
          createdAt
          updatedAt
          activitycustomEntriesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listActivitycustoms = /* GraphQL */ `
  query ListActivitycustoms(
    $filter: ModelActivitycustomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivitycustoms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        geometry
        polygon
        entries {
          items {
            id
            date
            description_short
            description_long
            photos
            createdAt
            updatedAt
            activitycustomEntriesId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEntry = /* GraphQL */ `
  query GetEntry($id: ID!) {
    getEntry(id: $id) {
      id
      date
      description_short
      description_long
      photos
      createdAt
      updatedAt
      activitycustomEntriesId
    }
  }
`;
export const listEntries = /* GraphQL */ `
  query ListEntries(
    $filter: ModelEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        description_short
        description_long
        photos
        createdAt
        updatedAt
        activitycustomEntriesId
      }
      nextToken
    }
  }
`;
