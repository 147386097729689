import { Link } from '@chakra-ui/react';
import ReactLinkify from 'react-linkify';

const componentDecorator = (href, text, key) => (
  <Link
    href={href}
    key={key}
    isExternal={true}
    color="blue.500"
    _focus={{ outline: 'none' }}
  >
    {text}
  </Link>
);

export const Linkify = (props) => {
  return <ReactLinkify {...props} componentDecorator={componentDecorator} />; };
