import {
  Modal,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/react'

import React, { useEffect } from 'react'
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Auth } from 'aws-amplify';
import "./login.css";

function Login(props) {

  // define useEffect hook that fires when component is rendered/changed
  useEffect(() => {
      async function getCurrentUser(){
        try {
            const user = await Auth.currentAuthenticatedUser();
            props.setCurrentUser(user.username)
            props.setAuthStatus(true)
        } catch {
          props.setCurrentUser(null)
          props.setAuthStatus(false)
        }
      }
      getCurrentUser();
    }
  )

  return (
    <>

      {/* handle auth */}
      <Modal isOpen={props.isAuthOpen} onClose={props.onAuthClose}>
        <ModalOverlay />
        <ModalContent>
         <Authenticator hideSignUp={true} >
            {({ signOut, user }) => (
              <div className='container'>
                <h1>Logged in as: {user.username}</h1>
                <button className='button' onClick={signOut}>Sign out</button>
              </div>
            )}
          </Authenticator>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Login;
