import { MapContainer, TileLayer, Polyline, Tooltip, Polygon } from "react-leaflet";
import { React, useState, useEffect } from 'react';
import L from 'leaflet';
import "leaflet/dist/leaflet.css";
import {
  Button,
  Text,
  AlertDialog,
  AlertDialogOverlay,
  AlertIcon,
  Alert,
  AlertDialogContent,
  AlertTitle,
  AlertDescription,
  useToast,
  useDisclosure
} from '@chakra-ui/react'
import { FiUser, FiDatabase } from 'react-icons/fi';
import { API, graphqlOperation } from "aws-amplify";
import Sidebar from '../sidebar'
import Legend from '../legend'
import Login from '../login'
import ActivityEdit from '../activityedit'
import ActivityDelete from '../activitydelete'
import DataViewer from "../dataViewer";
import Draw from '../draw'
import './map.css'
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries'
import Storage from "@aws-amplify/storage";

var polyUtil = require('polyline-encoded');

export function getColor(type) {
  var colors = {
    "Boat": "#FD314E",
    "Ride": "#F55AFA",
    "Run": "#7A33ED",
    "Ski": "#1BA1D7",
    "Hike": "#13C772",
    "Other": "#9E9200"
  };
  return colors[type]
}

function Map() {

  // setup disclosure for draw polyline mode
  const [drawMode, setDrawMode] = useState(false)
  const [isPolygon, setIsPolygon] = useState(false)

  // define state for chakra forms
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure()
  const { isOpen: isAuthOpenFail, onOpen: onAuthOpenFail, onClose: onAuthCloseFail } = useDisclosure()
  const { isOpen: isAuthOpen, onOpen: onAuthOpen, onClose: onAuthClose } = useDisclosure()
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()
  const { isOpen: isDataOpen, onOpen: onDataOpen, onClose: onDataClose } = useDisclosure()

  // set prop for current auth user
  const [authStatus, setAuthStatus] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  // set state for photos
  const [photoFileList, setPhotoFileList] = useState([]);

  // set state for activites
  const [mapObjects, setMapObjects] = useState(null);
  const [sidebarVisibility, setSidebarVisibility] = useState("none")
  const [currentActivity, setCurrentActivity] = useState({
    id: null,
    name: null,
    type: null,
    geometry: null,
    polygon: null,
    entries: [],
    createdAt: null,
    updatedAt: null,
  });

  const [currentEntry, setCurrentEntry] = useState({
    id: null,
    description_long: null,
    description_short: null,
    date: null,
    photos: null,
  })

  function clearCurrentActivity() {
    setPhotoFileList([])
    setCurrentActivity({
      id: null,
      name: null,
      type: null,
      geometry: null,
      polygon: null,
      entries: [],
      createdAt: null,
      updatedAt: null,
    })
  }

  function clearCurrentEntry() {
    setCurrentEntry({
      id: null,
      description_long: null,
      description_short: null,
      date: null,
    })
  }

  async function updateCurrentActivity(activityID) {
    const currentActivityResponse = await API.graphql(graphqlOperation(queries.getActivitycustom, { id: activityID }))
    setCurrentActivity(currentActivityResponse.data.getActivitycustom);
  }

  async function deletePhoto(path) {
    // list objects and delete
    const s3PathList = await Storage.list(path)
    for (const s3Path of s3PathList) {
      Storage.remove(s3Path.key)
    }
  }

  async function deleteActivity(activityID) {
    await API.graphql({ query: mutations.deleteActivitycustom, variables: { input: { 'id': activityID } } });
    deletePhoto(`map_entry_images/${activityID}`)
    toastConfirmation('Delete successful', 'Custom activity deleted successfully.');
    clearCurrentActivity();
    closeSidebar();
  }

  async function deleteEntry(activityID, entryID) {
    await API.graphql({ query: mutations.deleteEntry, variables: { input: { 'id': entryID } } });
    deletePhoto(`map_entry_images/${activityID}/${entryID}`)
    toastConfirmation('Update successful', 'Custom activity activities removed successfully.')
    updateCurrentActivity(currentActivity.id)
  }

  function openSidebar() {
    setSidebarVisibility("flex")
  }

  function closeSidebar() {
    setSidebarVisibility("none")
  }

  // function for uploading image files to s3
  // use graphql ids for paths
  function uploadImages(activityID, entryID, photoFileList) {
    Array.from(photoFileList).forEach(file => {
      Storage.put(
        `map_entry_images/${activityID}/${entryID}/${file.name}`,
        file,
        { contentType: file.type }
      )
    });
  }

  // upload data
  async function uploadData(values, actions, photoFileList) {

    // handle add condition
    // bone handle this location based on if activity exists or not
    if (currentActivity.id == null) {
      const newActivity = await API.graphql(graphqlOperation(mutations.createActivitycustom, {
        input: {
          'name': values.activityName,
          'type': values.activityType,
          'geometry': values.activityGeom,
          'polygon': values.activityPolygon,
        }
      }))
      const newEntry = await API.graphql(graphqlOperation(mutations.createEntry, {
        input: {
          'activitycustomEntriesId': newActivity.data.createActivitycustom.id,
          'date': values.activityDate,
          'description_short': values.activityDescShort,
          'description_long': values.activityDescLong,
          'photos': values.activityPhotos
        }
      }))
      uploadImages(newActivity.data.createActivitycustom.id, newEntry.data.createEntry.id, photoFileList)
      toastConfirmation('Add successful', 'Activity added successfully.');
      updateCurrentActivity(newActivity.data.createActivitycustom.id)
      onEditClose();
      // handle update entry condition
    } else if (currentEntry.id != null) {
      await API.graphql(graphqlOperation(mutations.updateEntry, {
        input: {
          'id': currentEntry.id,
          'activitycustomEntriesId': currentActivity.id,
          'date': values.activityDate,
          'description_short': values.activityDescShort,
          'description_long': values.activityDescLong,
          'photos': values.activityPhotos
        }
      }))
      uploadImages(currentActivity.id, currentEntry.id, photoFileList)
      toastConfirmation('Update successful', 'Entry updated successfully.');
      clearCurrentEntry();
      onEditClose();

      // handle new entry condition
    } else {
      const updatedEntry = await API.graphql(graphqlOperation(mutations.createEntry, {
        input: {
          'activitycustomEntriesId': currentActivity.id,
          'date': values.activityDate,
          'description_short': values.activityDescShort,
          'description_long': values.activityDescLong,
          'photos': values.activityPhotos
        }
      }))
      uploadImages(currentActivity.id, updatedEntry.data.createEntry.id, photoFileList)
      toastConfirmation('Update successful', 'Entry added successfully.')
      updateCurrentActivity(currentActivity.id)
      onEditClose();
    }
  }


  // define global toast confirmation
  const toast = useToast();
  function toastConfirmation(title, description) {
    toast({
      title: title,
      description: description,
      status: 'success',
      duration: 3000,
      isClosable: true,
    })
  }


  useEffect(() => {
    async function loadData() {
      // bone, should probably add auth error handling
      const customActivitiesResponse = await API.graphql({ query: queries.listActivitycustoms, filter: 'id', limit: 10000 });
      setMapObjects(customActivitiesResponse.data.listActivitycustoms.items.map((ca) =>
        ca.polygon == null || ca.polygon === false ?
          <Polyline
            key={ca.id}
            positions={polyUtil.decode(ca.geometry)}
            eventHandlers={{
              click: (e) => {
                openSidebar();
                updateCurrentActivity(ca.id);
              },
              mouseover: (e) => {
                var layer = e.target;
                layer.setStyle({
                  stroke: true,
                  weight: 7,
                  dashArray: '',
                  opacity: 0.7,
                });
              },
              mouseout: (e) => {
                var layer = e.target;
                layer.setStyle({
                  weight: 3,
                  dashArray: '',
                  opacity: 1
                });
              }
            }}
            pathOptions={{ color: getColor(ca.type) }}
          >
            <Tooltip>
              <Text ><b>{ca.name}</b></Text>
              <Text>{ca.type}</Text>
            </Tooltip>
          </Polyline>
          :
          <Polygon
            key={ca.id}
            positions={polyUtil.decode(ca.geometry)}
            eventHandlers={{
              click: (e) => {
                openSidebar();
                updateCurrentActivity(ca.id);
              },
              mouseover: (e) => {
                var layer = e.target;
                layer.setStyle({
                  stroke: true,
                  weight: 7,
                  dashArray: '',
                  opacity: 0.7,
                });
              },
              mouseout: (e) => {
                var layer = e.target;
                layer.setStyle({
                  weight: 3,
                  dashArray: '',
                  opacity: 1
                });
              }
            }}
            pathOptions={{ color: getColor(ca.type) }}
          >
            <Tooltip>
              <Text ><b>{ca.name}</b></Text>
              <Text>{ca.type}</Text>
            </Tooltip>
          </Polygon>
      )
      );
    }
    loadData();
  }, [currentActivity]  // include this so effect only runs once
  )

  return (
    <>
      <div className="map-container">
        <Sidebar
          sidebarVisibility={sidebarVisibility}
          currentActivity={currentActivity}
          closeSidebar={closeSidebar}
          openSidebar={openSidebar}
          onEditOpen={onEditOpen}
          onDeleteOpen={onDeleteOpen}
          deleteActivity={deleteActivity}
          deleteEntry={deleteEntry}
          onAuthOpenFail={onAuthOpenFail}
          authStatus={authStatus}
          clearCurrentActivity={clearCurrentActivity}
        />
        <div className="map">
          <MapContainer
            center={[39.7671, -105.0452]}
            zoom={10}
            scrollWheelZoom={true}
            renderer={
              L.canvas({
                tolerance: 3
              })
            }
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Legend />
            {mapObjects}
            <Draw
              drawMode={drawMode}
              setDrawMode={setDrawMode}
              setCurrentActivity={setCurrentActivity}
              currentActivity={currentActivity}
              onEditOpen={onEditOpen}
            />
          </MapContainer>
        </div>
      </div>
      <div className='login-buttons'>
        <Button m={1} id='login' onClick={onAuthOpen} size='xs' colorScheme='red' leftIcon={<FiUser />}>{currentUser != null ? currentUser : 'Login'}</Button>
        <Button m={1} id='add-data-new' onClick={(e) => {
          if (authStatus) {
            clearCurrentActivity();
            onEditOpen();
          } else {
            onAuthOpenFail();
          }
        }} size='xs' colorScheme='red' leftIcon={<FiUser />}>Add Data</Button>
        <Button m={1} id='tabular-data' onClick={onDataOpen} size='xs' colorScheme='red' leftIcon={<FiDatabase />}>View Data</Button>
      </div>
      <div>
        <ActivityEdit
          onEditOpen={onEditOpen}
          isEditOpen={isEditOpen}
          onEditClose={onEditClose}
          currentActivity={currentActivity}
          uploadData={uploadData}
          authStatus={authStatus}
          photoFileList={photoFileList}
          setPhotoFileList={setPhotoFileList}
          setDrawMode={setDrawMode}
          setIsPolygon={setIsPolygon}
          isPolygon={isPolygon}
          currentEntry={currentEntry}
          clearCurrentEntry={clearCurrentEntry}
          clearCurrentActivity={clearCurrentActivity}
        />
        <ActivityDelete
          onDeleteOpen={onDeleteOpen}
          isDeleteOpen={isDeleteOpen}
          onDeleteClose={onDeleteClose}
          currentActivity={currentActivity}
          deleteActivity={deleteActivity}
          deleteEntry={deleteEntry}
          onEditOpen={onEditOpen}
          setCurrentEntry={setCurrentEntry}
          uploadData={uploadData}
        />
        <DataViewer
          isDataOpen={isDataOpen}
          onDataOpen={onDataOpen}
          onDataClose={onDataClose}

        />
        <Login
          isAuthOpen={isAuthOpen}
          onAuthClose={onAuthClose}
          setCurrentUser={setCurrentUser}
          setAuthStatus={setAuthStatus}
        />
        <AlertDialog
          isOpen={isAuthOpenFail}
          onClose={onAuthCloseFail}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <Alert status='error'>
                <AlertIcon />
                <AlertTitle>Login required!</AlertTitle>
                <AlertDescription>Please login to make edits.</AlertDescription>
              </Alert>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </div>
    </>
  )
}

export default Map;
