import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
  Box,
  Text,
} from '@chakra-ui/react'
import './activitydelete.css'
import { React, useState, useEffect } from 'react';

function ActivityDelete(props) {

  const tableColumnNames = [
    "ID",
    "Date",
    "Description",
  ]

  // checkbox/table state and useEffect hook that updates on change
  const [tableValues, setTableValues] = useState([{
    'entry_id': null,
    'post_id': null,
    'activity_id': null,
    'description_short': null,
    'description_long': null,
    'date': null,
    'photos': null,
  }])
  const [checkedItems, setCheckedItems] = useState([])
  const [allChecked, setAllChecked] = useState(checkedItems.every(Boolean))
  const [isIndeterminate, setIndeterminate] = useState(checkedItems.some(Boolean) && !allChecked)

  useEffect(() => {
    if (props.currentActivity.entries.items !== undefined) {
      setCheckedItems(Array(props.currentActivity.entries.items.length).fill(false))
      setTableValues(props.currentActivity.entries.items.map(function (entry, i) {
        return {
          'activity_id': entry.activitycustomEntriesId,
          'entry_id': entry.id,
          'post_id': i,
          'description_short': entry.description_short,
          'description_long': entry.description_long,
          'date': entry.date,
          'photos': entry.photos,
        };
      }));
    }
  }, [props.currentActivity])

  useEffect(() => {
    setAllChecked(checkedItems.every(Boolean))
    setIndeterminate(checkedItems.some(Boolean) && !allChecked)
  }, [checkedItems, allChecked, isIndeterminate])

  // define function for deleting items by ID
  async function deleteSelectedItems() {
    // handle case where partial deletion
    if (!allChecked) {
      const deleteEntries = tableValues.filter((_, i) => checkedItems[i])
      for (const entry of deleteEntries) {
        props.deleteEntry(props.currentActivity.id, entry.entry_id)
      };

      // handle full delete
    } else {
      props.deleteActivity(props.currentActivity.id)
    }
  }

  async function editSelectedItems() {
    const editEntry = tableValues.filter((_, i) => checkedItems[i])
    props.setCurrentEntry({
      id: editEntry[0].entry_id,
      description_long: editEntry[0].description_long,
      description_short: editEntry[0].description_short,
      date: editEntry[0].date,
      photos: editEntry[0].photos
    })
    props.onEditOpen()

  }

  return (
    <>
      <Modal
        isOpen={props.isDeleteOpen}
        onClose={props.onDeleteClose}
        maxW="100rem"
        size='2xl'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Activity Entries</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box bg='gray#0.5' m={4} overflowX="auto" maxW='32rem'>
              <Text fontSize='md'>
                <b>Activity Name</b>: {props.currentActivity.name}
              </Text>
              <Text fontSize='md'>
                <b>Activity Type</b>: {props.currentActivity.type}
              </Text>
            </Box>
            <TableContainer>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th><Checkbox
                      onChange={(e) => {
                        setCheckedItems([...checkedItems].fill(e.target.checked))
                      }}
                      isChecked={allChecked}
                      isIndeterminate={isIndeterminate}
                    /></Th>
                    {tableColumnNames.map((name) =>
                      <Th key={name}>{name}</Th>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {tableValues.map((entry) =>
                    <Tr key={entry}>
                      <Th><Checkbox
                        isChecked={checkedItems[entry.post_id]}
                        onChange={(e) => {
                          var updateChecked = [...checkedItems];
                          updateChecked[entry.post_id] = e.target.checked;
                          setCheckedItems(updateChecked)
                        }}
                      /></Th>
                      <Td>{entry.post_id + 1}</Td>
                      <Td>{entry.date}</Td>
                      <Td>{
                        entry.description_short
                      }</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => {
              editSelectedItems();
              props.onDeleteClose();
            }} colorScheme='blue' disabled={checkedItems.filter(Boolean).length === 1 ? false : true} mr={3}>
              Edit Entry
            </Button>
            <Button onClick={() => {
              deleteSelectedItems();
              props.onDeleteClose();
            }} colorScheme='red' mr={3}>
              Delete Entry
            </Button>
            <Button onClick={() => {
              props.onDeleteClose();
              props.openSidebar();
            }}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ActivityDelete;
